import { Link } from "gatsby"
import React from "react"
import style from "./navMenu.module.scss"

const NavMenu = ({ mobileOpen }) => (
  <>
    <nav className={`${style.navMenu} ${mobileOpen ? style.navMenuOpen : ''}`}>
      <ul>
        <li>
          <Link to="/about">
            About
          </Link>
        </li>
        <li>
          <Link to="/work">
            Work
          </Link>
        </li>
        <li>
          <Link to="/services">
            Services
          </Link>
        </li>
        <li>
          <Link to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </>
)

export default NavMenu
