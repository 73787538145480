import React from "react"
import PropTypes from "prop-types"

import MainHeader from "./mainHeader"
import MainFooter from "./mainFooter"
import "./layout.css"

const MainLayout = ({ children }) => {
  return (
    <>
      <MainHeader siteTitle="Pitch Consulting"/>
        <div>
          <main>{children}</main>
        </div>
      <MainFooter/>
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
