import React from "react"
import PropTypes from "prop-types"
import style from "./banner.module.scss"

const Banner = ({ primaryText, secondaryText, backgroundImg }) => (
  <div className={style.banner} style={{ backgroundImage:`url(${backgroundImg})` }}>
    <h1>{primaryText}</h1>
    <p>{secondaryText}</p>
  </div>
)

Banner.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  backgroundImg: PropTypes.string,
}

Banner.defaultProps = {
  primaryText: ``,
  secondaryText: ``,
  backgroundImg: ``
}

export default Banner
