import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import NavMenu from "./navMenu"
import NavSocial from "./navSocial"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import style from "./mainHeader.module.scss"

const MainHeader = ({ siteTitle }) => {

  const [mobileOpen, setMobileOpen] = useState(0);

  const handleClick = () => {
    if (mobileOpen) {
      setMobileOpen(0);
    } else {
      setMobileOpen(1);
    }
  }

    return (
    <header className={style.mainHeader}>
      <h1 className={style.siteTitle}>
        <Link to="/" className={style.link}>
          {siteTitle}
          </Link>
      </h1>
      <div className={style.navs}>
        <NavMenu mobileOpen={mobileOpen}/>
        <NavSocial mobileOpen={0}/>
        <button className={style.mobileMenu} onClick={() => handleClick()}>
        {mobileOpen
          ? <FontAwesomeIcon icon={faTimes} />
          : <FontAwesomeIcon icon={faBars} />
        }
        </button>
      </div>
    </header>
  )
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
}

MainHeader.defaultProps = {
  siteTitle: ``,
}

export default MainHeader
