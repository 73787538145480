import React from "react"
import style from "./navSocial.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

const NavSocial = ({ mobileOpen }) => (
  <nav className={`${style.navSocial} ${mobileOpen ? style.navSocialOpen : ''}`}>
    <ul>
      <li>
        <a
          href="https://www.instagram.com/pitchconsulting/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/pitchconsult/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/pitchconsult"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
    </ul>
  </nav>
)

export default NavSocial
