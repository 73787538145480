import React from "react"
import style from "./mainFooter.module.scss"
import NavSocial from "./navSocial"

const MainFooter = () => (
  <footer className={style.mainFooter}>
    <div className={style.footerLeft}>
      <p><strong>Pitch Consulting</strong></p>
      <p><small>© {new Date().getFullYear()} Pitch Consulting</small></p>
    </div>
    <div className={style.footerRight}>
      <NavSocial mobileOpen={1}/>
    </div>
  </footer>
)

export default MainFooter
